import { byClass, firstByClass } from 'OK/utils/dom';
import LogSeen from 'OK/LogSeen';
import { ajax } from 'OK/utils/utils';
import { extractHookId } from 'OK/HookModel';

/**
 * @param {HTMLElement} element
 */
function activate(element) {
    if (element.hasAttribute('data-seen-params')) {
        LogSeen.activate(element);
    }

    byClass(element, 'js-scrollToComments').forEach(function (widget) {
        var offset = parseInt(widget.getAttribute('data-scroll-offset')),
            duration = parseInt(widget.getAttribute('data-scroll-duration'));
        widget.addEventListener('click', function () {
            scrollToComments(element, offset, duration);
        });
    });
}

/**
 * @param {HTMLElement} element
 */
function deactivate(element) {
    if (element.hasAttribute('data-seen-params')) {
        LogSeen.deactivate(element);
    }
}

function focusDiscussionForm(feed, offset, duration) {
    var comments = firstByClass(feed, 'comments');
    if (!comments) {
        return;
    }

    var id = comments.getAttribute('data-id'),
        type = comments.getAttribute('data-type');
    if (!(id && type)) {
        return;
    }

    Promise.all([
        import('OK/DiscussionManager').then(m => m && m.default || m),
        import('jquery').then(m => m && m.default || m)
    ]).then(function ([DiscussionManager, $]) {
        DiscussionManager.forEachOpened(id, type, function () {
            if (!$.contains(feed, this.element[0])) {
                return;
            }

            this.focusForm(offset, duration);
        });
    });
}

function scrollToComments(feed, offset, duration) {
    var commentsStub = firstByClass(feed, 'feed-comments-stub');
    if (commentsStub) {
        var link = commentsStub.getAttribute('data-link'),
            hookId = extractHookId(commentsStub.getAttribute('id'));

        ajax({
            type: 'POST',
            url: link
        }).done(function (data, status, xhr) {
            if (data == null) {
                return;
            }

            OK.hookModel.setHookContent(hookId, data);

            commentsStub.classList.remove('feed-comments-stub');

            focusDiscussionForm(feed, offset, duration);
        });
    } else {
        focusDiscussionForm(feed, offset, duration);
    }
}

export default { activate, deactivate };

export { activate, deactivate };
